import React, { Component } from 'react'
import {
  Button,
  Drawer,
  Typography,
  Toolbar,
  IconButton
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import NavigationArrow from '../NavigationArrow'
import { styled } from '@mui/material/styles'
const isBrowser = typeof window !== `undefined`

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  zIndex: 20,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

const DrawerContent = styled('div')(({ theme }) => `
  display: flex;
  align-items; flex-start;
  z-index: 20;
  padding: ${theme.spacing(0,1)};
  justify-content: flex-start;
  flex-direction: column;
  button {
    display: block;
    width: 100%;
    text-align: left;
    border-bottom: solid 1px #c8c8c8;
    border-radius: 0px;
    &:first-of-type {
      border-top: solid 1px #c8c8c8;
    }
  }
  button.selected {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAzMCAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzk0NV8xMDg4KSI+CjxjaXJjbGUgY3g9IjE1IiBjeT0iMTUuOTgxOCIgcj0iMTUiIGZpbGw9IiMyRjgxMkQiLz4KPHBhdGggZD0iTTIzIDkuOTgxODFMMTIgMjAuOTgxOEw3IDE1Ljk4MTgiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzk0NV8xMDg4Ij4KPHJlY3Qgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjk4MTgxMikiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.4rem;
  }
  button.primary {
    border: none;
    color: #fff;
    background: #1B3E65;
    text-align: center;
    padding: 6px 8px;
    line-height: 1.75;
  }
  button.MuiButton-containedPrimary {
    color: #fff;
  }
  button.secondary {
    border: none;
    background: #c0c0c0;
    text-align: center;
    padding: 6px 8px;
    line-height: 1.75;
  }
`)

class PopInPanel extends Component {
  constructor(props) {
    super(props)
    let {innerWidth, innerHeight} = 0
    if(isBrowser){
      innerWidth = window.innerWidth
      innerHeight = window.innerHeight
    }
    this.state = {
      innerWidth: innerWidth,
      innerHeight: innerHeight
    }
  }

  componentDidMount(){

  }

  render() {
    let drawerWidth = '100%'
    if(this.state.innerWidth > 800){
      drawerWidth = '600px'
    }
    let classOpen = ''
    if(this.props.open){
      classOpen += ' open'
    }
    if(this.props.maxWidth){
      drawerWidth = this.props.maxWidth
    }
    return(
      <Drawer
        className={'pop-in-panel'+classOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            maxWidth: drawerWidth,
          },
          overflow: 'hidden'
        }}
        variant="persistent"
        anchor="bottom"
        open={this.props.open}
      >
         <DrawerHeader>
          {this.props.handleDrawerClose &&
            <IconButton onClick={this.props.handleDrawerClose}>
              {this.props.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          }
           <Typography className='drawer-title' variant="h6" noWrap
            sx={{ flexGrow: 1, textAlign: 'center'}}>
             {this.props.title}
           </Typography>
         </DrawerHeader>
         <DrawerContent style={{
           padding: '1rem',
           flexGrow: 1,
           overflowX: 'hidden',
           overflowY: 'auto'
         }}>
           {this.props.children}
         </DrawerContent>
         {this.props.extraFunctions &&
           <>
           {this.props.extraFunctions}
           </>
         }
         {this.props.applyFunction &&
           <Button variant="contained" onClick={this.props.applyFunction}>
           {this.props.applyFunctionLabel ? this.props.applyFunctionLabel : 'Apply'}
           </Button>
         }
         {!this.props.hideCancel &&
           <Button onClick={this.props.handleClose}>Cancel</Button>
         }
      </Drawer>
    )
  }
}

export default PopInPanel
