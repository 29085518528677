import React, { Component } from 'react'
import {
  Button,
  TextField } from '@mui/material'
import { updateProject } from '../../utils/project'
import styled from 'styled-components'
const isBrowser = typeof window !== `undefined`

class FormNewProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount(){

  }

  render() {
    return(
      <FormWrapper>
      <form
        className="entry-form"
        method="post"
        onSubmit={event => {
          this.props.handleSubmit(event)
        }}
        style={{
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'space-between',
         margin: '0',
         flexGrow: 1
       }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          flexGrow: 1
        }}>
          <div className="required">
          <TextField
           required
           id="name"
           name="name"
           value={this.props.project?.name || ''}
           placeholder="Untitled"
           label="Project Name"
           variant="filled"
           onChange={this.props.handleUpdate}
          />
          </div>
          <TextField
           id="zip"
           name="zip"
           value={this.props.project?.zip || ''}
           placeholder="Location"
           label="Project ZIP Code"
           variant="filled"
           onChange={this.props.handleUpdate}
           className='location-input'
          />
        </div>
        <Button type="submit" variant="contained" style={{
          backgroundColor: '#2F802D',
          textAlign: 'center'
        }}>Update</Button>
      </form>
      </FormWrapper>
    )
  }
}


class ProjectFormNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errMsg: '',
      disableSubmit: false
    }
  }

  componentDidMount(){

  }

  handleUpdate = (e) => {
    let project = {...this.props.project}
    project[e.target.name] = e.target.value
    this.setState(
      {errMsg: ''}
    )
    this.props.handleUpdate(project)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.handleSetLoading(true)
    if(isBrowser){
      updateProject(this.props.project)
      .then((data) => {
        this.props.onComplete(data)
      })
      .catch((err) => {
        this.setState({
          errMsg: err.message
        })
      })
    }
  }

  render() {
    let productName = ''
    if(this.props?.product?.product_name){
      productName = this.props.product.product_name
    }
    return(
      <>
      <FormNewProject
        handleUpdate={this.handleUpdate}
        handleCancel={this.props.handleCancel}
        country={this.state.country}
        handleSubmit={this.handleSubmit}
        project={this.props.project}
        productName={productName}
        showAddToProject={this.props.showAddToProject} />
      <div>{this.state.errMsg}</div>
      </>
    )
  }
}

export default ProjectFormNew

const FormWrapper = styled.section`
height: 100%;
position: relative;
display: flex;
flex-direction: column;
flex-grow: 1;
.location-input label{
  padding-left: 1.5rem;
}
#zip{
  padding-left: 2rem;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxIDEwQzIxIDE3IDEyIDIzIDEyIDIzQzEyIDIzIDMgMTcgMyAxMEMzIDcuNjEzMDUgMy45NDgyMSA1LjMyMzg3IDUuNjM2MDQgMy42MzYwNEM3LjMyMzg3IDEuOTQ4MjEgOS42MTMwNSAxIDEyIDFDMTQuMzg2OSAxIDE2LjY3NjEgMS45NDgyMSAxOC4zNjQgMy42MzYwNEMyMC4wNTE4IDUuMzIzODcgMjEgNy42MTMwNSAyMSAxMFoiIHN0cm9rZT0iIzcwNzA3MCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEyIDEzQzEzLjY1NjkgMTMgMTUgMTEuNjU2OSAxNSAxMEMxNSA4LjM0MzE1IDEzLjY1NjkgNyAxMiA3QzEwLjM0MzEgNyA5IDguMzQzMTUgOSAxMEM5IDExLjY1NjkgMTAuMzQzMSAxMyAxMiAxM1oiIHN0cm9rZT0iIzcwNzA3MCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-position: .25rem center;
  background-repeat: no-repeat;
}
`
