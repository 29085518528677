import axios from 'axios'
const restPath = process.env.GATSBY_APIURL

export const getTaxRate = (searchText) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(restPath + '/tax-rate', {
          searchText: searchText
        },
          {headers: {'Content-Type': 'application/json'}})
        .then(function(data) {
          if(data?.data?.results){
            let rate = {
              rate: 0,
              handlingTaxable: true,
              freightTaxable: true
            }
            let noTax = ['IA','UT']
            let freightNotTaxable = ['AZ','CA','CO','FL','GA','KS','MN','NC','NE','NV','NY','OH','SC','TN','TX','VA','WA']
            if(data?.data?.results[0]){
              rate.rate = data.data.results[0].taxSales
              console.log(data.data.results[0])
              if(noTax.includes(data.data.results[0].geoState)){
                rate.handlingTaxable = false
                rate.freightTaxable = false
              }
              if(freightNotTaxable.includes(data.data.results[0].geoState)){
                rate.freightTaxable = false
              }
              rate.geoState = data.data.results[0].geoState
              rate.geoCity = data.data.results[0].geoCity.split(' ').map((word)=>{
                return word[0].toUpperCase() + word.substring(1).toLowerCase()
              }).join(' ')
            }
            resolve(rate)
          }else{
            let dataError = new Error('Malformed Response')
            reject(dataError)
          }
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
